import Link from 'next/link';
import { useMemo } from 'react';

export type AppStoreBadgeProps = {
  /**
   * The platform to display the badge for.
   */
  platform: 'ios' | 'android';

  /**
   * The style of the badge to display.
   */
  style: 'badge' | 'qrcode';
};

export function AppStoreBadge(props: AppStoreBadgeProps): JSX.Element {
  const { platform = 'ios', style = 'badge' } = props;

  const config = {
    // Generated using https://tools.applemediaservices.com/app/6446509018?country=us
    ios: {
      href: 'https://apps.apple.com/us/app/groupthink-meeting-notes/id6446509018?itsct=apps_box_badge&amp;itscg=30200',
      badgeImageURL:
        'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1700611200',
      badgeImageStyle: {
        width: '250px',
        height: '83px',
        borderRadius: '13px',
      },
      alt: 'Download on the App Store',
      qrCodeImageURL:
        'https://tools-qr-production.s3.amazonaws.com/output/apple-toolbox/b435bc994b8bf4b0cb50c89f42d3d769/c59e400d1cba897794135e8e46baeb08.png',
    },
    // Generated using https://play.google.com/intl/en_us/badges/
    android: {
      href: 'https://play.google.com/store/apps/details?id=com.groupthink.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
      badgeImageURL:
        'https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png',
      badgeImageStyle: {
        width: '323px',
        height: '125px',
        borderRadius: '0',
      },
      alt: 'Get it on Google Play',
      qrCodeImageURL: '',
    },
  };

  const metadata = useMemo(() => {
    if (platform === 'ios' || platform === 'android') {
      return config[platform];
    }

    return null;
  }, [config, platform]);

  if (!metadata) {
    return null;
  }

  if (style === 'badge' && metadata.href && metadata.badgeImageURL) {
    return (
      <Link href={metadata.href}>
        <img src={metadata.badgeImageURL} alt={metadata.alt} style={metadata.badgeImageStyle} />
      </Link>
    );
  }

  if (style === 'qrcode' && metadata.qrCodeImageURL) {
    return <img src={metadata.qrCodeImageURL} />;
  }

  return null;
}
