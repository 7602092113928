import { useCallback } from 'react';

const useDataLayer = () => {
  const pushData = useCallback((event, data) => {
    if (typeof window !== 'undefined' && Boolean(window.dataLayer)) {
      window.dataLayer.push({ event, ...data });
    }
  }, []);

  return { pushData };
};

export default useDataLayer;
