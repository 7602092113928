import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/joy';

interface RemainingHeightBoxProps {
  children: React.ReactNode;
  measureRef: React.RefObject<HTMLElement>;
  fallbackHeight?: number;
  sx?: object;
}

/**
 * A Box component that fills the remaining vertical space after accounting for another element's height.
 * Automatically adjusts when the measured element's height changes (e.g. on resize, content changes, or font load).
 *
 * @example
 * ```tsx
 * const headerRef = useRef<HTMLDivElement>(null);
 *
 * return (
 *   <>
 *     <Header ref={headerRef} />
 *     <RemainingHeightBox measureRef={headerRef}>
 *       <YourContent />
 *     </RemainingHeightBox>
 *   </>
 * );
 * ```
 *
 * @param measureRef - Ref to the element whose height should be subtracted
 * @param sx - Additional MUI system props for the Box
 */
export const RemainingHeightBox = ({ children, measureRef, sx = {} }: RemainingHeightBoxProps) => {
  const [elementHeight, setElementHeight] = useState(0);

  const updateHeight = useCallback(() => {
    if (measureRef.current) {
      setElementHeight(measureRef.current.offsetHeight);
    }
  }, [measureRef]);

  useEffect(() => {
    updateHeight();
    window.addEventListener('resize', updateHeight);
    window.addEventListener('load', updateHeight);

    const observer = new MutationObserver(updateHeight);
    if (measureRef.current) {
      observer.observe(measureRef.current, {
        subtree: true,
        childList: true,
        characterData: true,
      });
    }

    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('load', updateHeight);
      observer.disconnect();
    };
  }, [updateHeight]);

  return (
    <Box
      sx={{
        height: `calc(100% - ${elementHeight}px)`,
        ...sx,
      }}>
      {children}
    </Box>
  );
};
