import { Switch as DefaultSwitch, SwitchProps as DefaultSwitchProps } from '@mui/joy';

export type SwitchProps = DefaultSwitchProps & {
  /**
   * Invoked when the user tries to change the value of the switch. Receives the new value as an argument.
   * If you want to instead receive an event, use onChange.
   *
   * @param newValue
   * @returns
   */
  onValueChange?: (newValue: boolean) => void;
};
/**
 * Switches toggle the state of a single setting on or off.
 *
 * This is a controlled component that requires an `onValueChange` or `onChange` callback that updates the value prop in order for the component to reflect user actions.
 * If the value prop is not updated, the component will continue to render the supplied value prop instead of the expected result of any user actions.
 *
 * @param props
 * @returns JSX.Element
 */
export function Switch(props: SwitchProps): JSX.Element {
  const { checked, onChange, onValueChange, ...rest } = props;

  return (
    <DefaultSwitch
      color={checked ? 'primary' : 'neutral'}
      variant={checked ? 'solid' : 'outlined'}
      onChange={(e) => {
        onChange?.(e);
        onValueChange?.(e.target.checked);
      }}
      checked={checked}
      {...rest}
    />
  );
}
