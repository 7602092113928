import Typography from '@mui/joy/Typography';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/system';

const WelcomeHeading = () => {
  const theme = useTheme();
  const matchesSmallMediaQuery = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Typography level={matchesSmallMediaQuery ? 'display2' : 'display1'}>
      Welcome to{' '}
      <Typography sx={(theme) => ({ color: theme.vars.palette.primary[500] })}>
        Groupthink
      </Typography>
    </Typography>
  );
};

export default WelcomeHeading;
