import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import {
  MediaPlayer,
  MediaPlayerInstance,
  MediaPlayerProps,
  MediaProvider,
  Poster,
  useStore,
} from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import DownloadIcon from '@mui/icons-material/Download';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import ListDivider from '@mui/joy/ListDivider';
import ListItem from '@mui/joy/ListItem';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Stack from '@mui/joy/Stack';
import Tooltip from '@mui/joy/Tooltip';
import Typography from '@mui/joy/Typography';
import React, { useEffect, useRef } from 'react';
import useDataLayer from '@/hooks/dataLayer';

interface VideoPlayerProps {
  videoSource: MediaPlayerProps['src'];
  thumbnailSource?: string;

  /**
   * Setting this value seeks the media to the new time.
   * https://www.vidstack.io/docs/player/components/core/player?styling=default-theme#mediaplayer.props
   */
  currentTime?: MediaPlayerProps['currentTime'];

  /**
   * Show playback rate controls in the player.
   */
  playbackRateControls?: boolean;

  /**
   * Show button to download the video.
   */
  downloadFilename?: string;

  style?: MediaPlayerProps['style'];
}
export default function VideoPlayer({
  videoSource,
  thumbnailSource,
  currentTime = 0,
  playbackRateControls = false,
  downloadFilename = 'meeting-recording',
  style: $styleOverride = {},
}: VideoPlayerProps) {
  const style = {
    borderRadius: '0',
    border: 'none',
    ...$styleOverride,
  };

  const { pushData } = useDataLayer();

  const ref = useRef<MediaPlayerInstance>(null),
    { playbackRate: _playbackRate } = useStore(MediaPlayerInstance, ref);

  const [playbackRate, setPlaybackRate] = React.useState<MediaPlayerProps['playbackRate']>(
    _playbackRate ?? 1.0
  );

  useEffect(() => {
    setPlaybackRate(_playbackRate);
  }, [_playbackRate, setPlaybackRate]);
  return (
    <Box>
      <MediaPlayer
        ref={ref}
        playsinline
        src={videoSource}
        style={style}
        currentTime={currentTime}
        playbackRate={playbackRate}
        aspectRatio="16/9"
        onPlay={() =>
          pushData('Video Played', {
            button_name: 'Play Video',
            button_intent: 'play video',
            button_context: downloadFilename,
          })
        }
        onPause={() =>
          pushData('Video Paused', {
            button_name: 'Pause Video',
            button_intent: 'pause video',
            button_context: downloadFilename,
          })
        }
        streamType="on-demand">
        <MediaProvider>
          {thumbnailSource && <Poster className="vds-poster" src={thumbnailSource} />}
        </MediaProvider>
        <DefaultVideoLayout
          icons={defaultLayoutIcons}
          style={style}
          download={{
            url: videoSource as string,
            filename: `${downloadFilename}.mp4`,
          }}
        />
      </MediaPlayer>
    </Box>
  );
}

interface PlaybackRateButtonProps {
  playbackRate: MediaPlayerProps['playbackRate'];
  setPlaybackRate: (playbackRate: MediaPlayerProps['playbackRate']) => void;
}
/**
 * Controlled button with a dropdown menu to change the playback speed of the video.
 * Typically only displays options between 0.5x and 2.0x, in steps of 0.5x.
 * The embedded video player settings menu allows for more granular options, but this
 * button will only show these in the dropdown menu when they're selected, otherwise
 * they remain hidden.
 */
function PlaybackRateButton({ playbackRate, setPlaybackRate }: PlaybackRateButtonProps) {
  const PlaybackSpeedItem = ({ speed, label }: { speed: number; label?: string }) => (
    <MenuItem selected={playbackRate === speed} onClick={() => setPlaybackRate(speed)}>
      {label ?? `${speed}x`}
    </MenuItem>
  );

  return (
    <Dropdown>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { color: 'neutral' } }}
        aria-label="Change playback speed">
        <Tooltip title="Playback speed" variant="solid" placement="top">
          <SlowMotionVideoIcon />
        </Tooltip>
      </MenuButton>
      <Menu>
        <ListItem sticky>
          <Typography
            id={`playback-speed-label`}
            level="body-sm"
            textTransform="uppercase"
            fontWeight="lg">
            Playback speed
          </Typography>
        </ListItem>
        <ListDivider />
        {playbackRate === 0.25 && <PlaybackSpeedItem speed={0.25} />}
        <PlaybackSpeedItem speed={0.5} />
        {playbackRate === 0.75 && <PlaybackSpeedItem speed={0.75} />}
        <PlaybackSpeedItem speed={1.0} label={'Normal'} />
        {playbackRate === 1.25 && <PlaybackSpeedItem speed={1.25} />}
        <PlaybackSpeedItem speed={1.5} />
        {playbackRate === 1.75 && <PlaybackSpeedItem speed={1.75} />}
        <PlaybackSpeedItem speed={2.0} />
      </Menu>
    </Dropdown>
  );
}
